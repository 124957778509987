
export const getCategories = async (campaignId) => {
    const url = `${process.env.REACT_APP_TEST_URL}/marketplace/list-categorys?campaign=${campaignId}`;
    //const url = `${process.env.REACT_APP_TEST_URL}/list-categorys?campaign=61dc864fb38faa18a457f922`;

    const resp = await fetch(url);
    const data = await resp.json();

    const categories = data.map(category => {
        return {
            _id: category._id,
            name: category.name,
            img_url: category.img_url
        }
    })

    return categories;
}