
export const getProductsByCategories = async (_id) => {
    const url = `${process.env.REACT_APP_TEST_URL}/marketplace/get-category-id?categoryId=${_id}`;
    const resp = await fetch(url);
    const { products } = await resp.json();

    const categoriesP = products?.map(product => {
        const imageUrl = Array.isArray(product.imageUrl) ? product.imageUrl : [product.imageUrl];
        return {
            _id: product._id,
            active: product.active,
            name: product.name,
            descriptionProduct: product.descriptionProduct,
            salePrice: product.salePrice,
            imageUrl,
        }
    })

    return categoriesP;
}