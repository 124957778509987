import React, { useState, useEffect } from 'react';
import { HomeCategories, HomePageContainer, HomePromos } from '../style';
import ImageGallery from 'react-image-gallery';
import './home-page.scss';
import propTypes from 'prop-types';
import { HomeImages } from './HomeImages';
import { getPromotions } from '../../../services/getPromotions';
import { useParams } from 'react-router';
import "../../../styles/home-image-gallery.scss";

export const HomePage = ({ categories, showInfo }) => {
    const [promotions, setPromotions] = useState([]);
    const { storeId } = useParams();

    useEffect(() => {
        getPromotions(storeId)
            .then(setPromotions);
    }, [storeId]);

    const images = promotions.map((img) => {
        return {
            original: img.imageUrl[0],
            description: img.promotionDescription,
        }
    });

    return (
        <HomePageContainer>
            {promotions && promotions.length > 0 && (
                <HomePromos>
                    <ImageGallery items={images}
                        additionalClass="image"
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={false}
                        showBullets={!showInfo}
                        autoPlay
                        slideDuration={450}
                    />
                </HomePromos>
            )}
            <HomeCategories>
                <HomeImages categories={categories} />
            </HomeCategories>
        </HomePageContainer >
    );
};

HomePage.propTypes = {
    categories: propTypes.array,
    showInfo: propTypes.bool,
}